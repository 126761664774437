<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>
          Wachtwoord vergeten
        </h1>
      </v-col>

      <v-col cols="12" class="mb-4">
        Vul je emailadres in en we helpen je opweg!
      </v-col>

      <v-col cols="12" class="bm-4">
        <form v-on:submit.prevent="" v-on:keyup.enter="submit">
          <v-text-field
              v-model="email"
              label="Email"
              required
          ></v-text-field>

          <v-btn color="accent" class="mr-4" block @click="submit" :loading="loading" :disabled="loading">Wachtwoord aanvragen</v-btn>
        </form>
      </v-col>
    </v-row>

    <snackbar-text text="Wachtwoord aanvragen mislukt" bg-color="red" text-color="white" v-model="showError" />

    <snackbar-text :text="`Wahtwoord aanvragen gelukt, er is een email gestuurd naar ${this.email} met een bevestigingscode.`"
                   bg-color="green"
                   text-color="white"
                   v-model="showSuccess"
                   :button="false"
                   :timeout="4000"
    />
  </v-container>
</template>

<script>

import apiClient from "@/services/apiClient";
import SnackbarText from "@/components/snackbar-text";

export default {
  name: "ForgotPassword",
  components: {SnackbarText},
  data: () => ({
    email: null,
    showError: false,
    showSuccess: false,
    loading: false,
  }),
  methods: {
    submit() {
      this.loading = true;

      let params = {
        email: this.email
      }

      apiClient.post("auth/forgot_token", params).then(response => {
        this.showSuccess = true;

        setTimeout(() => {
          this.$router.push({name: 'resetPassword', params: params})
        }, 4000);
      }).catch(error => {
        this.showError = true;
        this.loading = false;
      });
    }
  },
};
</script>
